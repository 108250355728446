import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import caro from './images/hotel.jpg';

function MultipleItems() {
    const imageStyle = {
        width: "99.5%", // Hacer que el ancho sea el 100% para ser responsive
        height: "400px", // Puedes ajustar la altura según tus necesidades
    };

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <img src={caro} alt="" style={imageStyle} />
        </div>
        <div>
          <img src={caro} alt="" style={imageStyle} />
        </div>
        <div>
          <img src={caro} alt="" style={imageStyle} />
        </div>
        <div>
          <img src={caro} alt="" style={imageStyle} />
        </div>
        <div>
          <img src={caro} alt="" style={imageStyle} />
        </div>
        <div>
          <img src={caro} alt="" style={imageStyle} />
        </div>
      </Slider>
    </div>
  );
}

export default MultipleItems;
