import { Navbar, Container, Nav, Carousel } from 'react-bootstrap';
import logo from './images/H7.png'
import Flip from 'react-reveal/Flip';
import { Outlet, Link } from "react-router-dom"
import SocialMediaLinks from './socialicons';
import './css/navbar.css';
import React, { useEffect, useState } from 'react';
import fondo from './images/fondo1.jpg';


function Menu() {
  const phoneNumber = "5540076098"; // Reemplaza con tu número de teléfono real

  const handlePhoneClick = () => {
    const telLink = `tel:${phoneNumber}`;
    window.open(telLink, '_blank');
  };
//EFECTO SCROLL DEL TITUTLO///////////////////////////
  const [scrollOpacity, setScrollOpacity] = useState(1);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const maxScroll = window.innerHeight * 0.5; // Ajusta según sea necesario

      const newOpacity = 1 - scrollPosition / maxScroll;
      setScrollOpacity(Math.max(0, newOpacity));
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
 
  ///////////////////////////////
  
  return (
<div>
<section className='Menu'>
  
      
    <Navbar.Brand className="d-block text-center fixed-res fixed-top" as={Link} to="/">
    <Flip left><img alt="logo" className='logo' src={logo} width="10%" height="auto" /></Flip>
    </Navbar.Brand>
      <Navbar  expand="lg"  fixed="top" className=' fixed-res  nav-espace'>
      <Container>
      <div className="link-color">
            <span onClick={handlePhoneClick} className="  navbar-text me-auto text-nav">Teléfono: {phoneNumber}</span>
          </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" className='link-color'>Home</Nav.Link>
            <Nav.Link as={Link} to="/Contacto" className='link-color'>Contacto</Nav.Link>
            <Nav.Link as={Link} to="/Contacto" className='link-color'>Contacto</Nav.Link>
          </Nav>
          <Nav className="ms-auto">
        <SocialMediaLinks />
      </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
              <section>
              <Outlet>
              </Outlet>
              </section> 
         
</section>

  <div className="carousel-container">
        <Carousel>
          <Carousel.Item>
            <img className=" responsive-image" src={fondo} alt="Primer Slide" />
            <Carousel.Caption>
            <div className="scrolling-title" style={{ opacity: scrollOpacity }}>
            <h1 className=" title-caru">El último escape con el que has estado soñando.</h1>
    </div> 
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
    </div>
  
      </div>
  );
}

export default Menu;