
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';


//importamos los comp creados
import Home from './home';

function App() {
  return (
    
    <div className="App">
<BrowserRouter>
<Routes>
  <Route>
    <Route index element={ <Home/> } />
    <Route path='Home' element={ <Home /> } />
    <Route path='*' element={ <Navigate replace to="/"/> }/>
  </Route>
</Routes> 
</BrowserRouter>

    </div>
  );
}

export default App;
