import Menu from './Navbar';
import {Row, Col,Button,Form,Nav,Toast } from 'react-bootstrap';
import { Container, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel3 from './carousel.jsx';
import './css/home.css'
import imgsec1 from './images/1.jpg'
import logoImage from './images/icon1.png'
import hotelpara from './images/hotelpara.webp'
import { Parallax } from 'react-parallax';
import logo from './images/H72.png'
import emailjs from '@emailjs/browser';
import WhatsAppFloatingButton from '../contenido/whatsapp.jsx';
import React, { useRef,useState} from 'react';
import { Link } from "react-router-dom"
import Cookies from 'js-cookie';


function Home() {
   //PARA ENVIAR AL CORREO///////
   const form = useRef();
   const sendEmail = (e) => {
     e.preventDefault();
     emailjs.sendForm('service_1hdzjig', 'template_3igjh37', e.target, '_8v_Hvo4P88SblOEw')
       .then((result) => {
           console.log(result.text);
           window.location.reload();
       }, 
       (error) => {
           console.log(error.text);
       }
       );
   };
////////////COOKIES///////////////////////
const [showBanner, setShowBanner] = useState(true);
const handleAcceptCookies = () => {
  // Establecer la cookie de aceptación con una duración de 365 días
  Cookies.set('cookieAccepted', 'true', { expires: 365 });
  setShowBanner(false);
};
///////////////////////////////////////////
  return (

    <div>
       <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', padding: '10px', zIndex: 1000 }}>
      <Toast show={showBanner} onClose={() => setShowBanner(false)}>
        <Toast.Header>
          <strong className="me-auto">Mensaje de Cookies</strong>
        </Toast.Header>
        <Toast.Body>
          Este sitio web utiliza cookies para mejorar la experiencia del usuario.
          <Button variant="success" onClick={handleAcceptCookies}>
            Aceptar cookies
          </Button>
        </Toast.Body>
      </Toast>
    </div>
  <WhatsAppFloatingButton/>
  {/* ////////////////////////////////////////////////////// */}
    <section className='Menu mb-5'>
            <Menu/>
    </section>

    <section className='section 1 my-5 background-container'>
    <Container>
      <Row>
        {/* Texto en el lado izquierdo */}
        <Col md={6}>
          <div className='section1 '>
            <h2 className='titulo mb-4'>
            <span className='titulospan'>  
            ¡Una exquisita <br></br>combinación&nbsp; 
            de paz y diversión en compañía de los que amas!
            </span>
           
            </h2>
            <p className='parrafo mb-4'>
            Situada en tecolutla veracruz , anteriormente bajo el nombre de Hotel 7 Mares, es un encantador hotel diseñado exclusivamente para familias y grupos de amigos que buscan diversión y serenidad frente a las hermosas playas de veracruz.
                         
            </p>
            <p className='parrafo mb-4'>
            Este encantador Hotel se encuentra 15-20 minutos del centro y de la zona arqueológica de veracruz.
            La ubicación ideal para explorar lo más popular de tecolutla veracruz, pero lo suficientemente alejado del bullicio y la vibrante vida del centro.
            </p>
            <Button className="boton-mar">
            <span className="flecha">&rarr;</span> Explorar
           </Button>
          </div>
        </Col>

        {/* Imagen en el lado derecho */}
        <Col md={6}>
        <Container className="contenedor-imagen">
      <div className="imagen-con-contorno">
      <div className="logo-circulo img-fluid">
        <Image
            src={logoImage}
            alt="Logo"
            className="logo-img"
          />
      </div>
      <Image
        src={imgsec1}
        alt="Imagen"
        className="img-fluid img-1"
      />
      <div className="borde"></div>
      </div>
    </Container>
        </Col>
      </Row>
    </Container>
    </section>

{/* ////////////////////////////////////////////////////////////// */}
    <section className='section2 my-5'>
    <Carousel3/>
    </section>
{/* ////////////////////////////////////////////////////////////// */}

    <section  className='section3 m-5 '>
      <Row>
      <Col md={6}>
        <Container className="contenedor-imagen">
      <div className="imagen-con-contorno">
      <div className="logo-circulo img-fluid">
        <Image
            src={logoImage}
            alt="Logo"
            className="logo-img"
          />
      </div>
      <Image
        src={imgsec1}
        alt="Imagen"
        className="img-fluid imgsecond"
      />
      <div className="borde"></div>
      </div>
    </Container>
        </Col>
{/* ////////////////////////////////////////////////////////////// */}
        <Col md={6}>
        <div className='section3'>
            <h2 className='titulo2 mb-4'>
            <span className='titulospan2'>  
            Diseñado para satisfacer<br></br>a&nbsp; 
            </span>
            parejas, familias y grupos de amigos.
            </h2>
            <p className='parrafo2 mb-4'>
            Además de incomparables vistas a la playa linda, habitaciones con vista al mar y una playa tranquila, este hotel te brinda las comodidades para tener una estancia inolvidable.</p>
            <p className='parrafo2 mb-3'>
            Deja que el sonido de las olas entre en tu habitación y disuelva todo tu estrés.
            </p>
          </div>
        </Col>
      </Row>
    </section>
{/* ////////////////////////////////////////////////////////////// */}

    <section className='section 4 mb-5'>
    <div>
    <Parallax
      blur={0} // Configura blur a 0 para quitar el efecto degradado
      bgImage={hotelpara}
      bgImageAlt="Parallax Background"
      strength={200}
      style={{ height: '100vh' }}
    >
      <div className="parallax-overlay"></div>
      <div className="parallax-container">
        <Container className="text-white">
          <div className="text-center">
            <img src={logo} alt="Logo" className="logo-pallx mb-5"/>
            <h1 className='titlex mb-5'>Esto es Tecolutla <br></br> Veracruz.</h1>
            <p className='parrafox'>Camina hasta cerca de la playa y admira el zulado y tranquilo del Golfo Mexicano, obtén una experiencia inigualable.</p>
          </div>
        </Container>
      </div>
    </Parallax>
    </div>
    </section>

    <section className='section5 '>
 
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3737.306172522775!2d-97.02822482600743!3d20.493671405944177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85dbb41489da67ed%3A0x92d2423c71db1371!2sHotel%207%20Mares!5e0!3m2!1ses!2smx!4v1708556775461!5m2!1ses!2smx"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
   
    </section>


    <section>
      <div>
<Container className="d-flex align-items-center justify-content-center min-vh-100 mt-5">
<Form ref={form} onSubmit={sendEmail}  className="form-inline formulario">

    <Row className="mb-3">
      <Form.Group as={Col} controlId="nombre">
        <Form.Label>Nombre</Form.Label>
        <Form.Control type="text" name="nombre" placeholder="Nombre" />
      </Form.Group>

      <Form.Group as={Col} controlId="apellido">
        <Form.Label>Apellido</Form.Label>
        <Form.Control type="text" name="apellido"  placeholder="Apellido" />
      </Form.Group>
    </Row>

    <Row className="mb-3">
 
    <Form.Group as={Col} className="mb-3" controlId="telefono">
        <Form.Label>Teléfono</Form.Label>
        <Form.Control type="tel" name="telefono" placeholder="Ingrese su número de teléfono" required />
      </Form.Group>
    </Row>


          <Form.Group className="mb-3" controlId="comentarios">
            <Form.Label>Comentarios</Form.Label>
            <Form.Control as="textarea"  rows={3} name="comentarios" placeholder="Ingrese sus comentarios" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="aceptarTerminos">
            <Form.Check
              type="checkbox"
              name="aceptarTerminos"
              label="Aceptar términos y condiciones"
              required
            />
          </Form.Group>

          <Button  variant="primary" type="submit" >Enviar Formulario</Button>

  </Form>
  </Container>
      </div>
    </section>

    <section>
    <footer className='footer mt-5'>
      <Container>
        <Row>
          <Col md={4}>
            <p>Nuestra misión es incentivar el crecimiento económico, nacional, regional y sectorial, mediante el fomento a la productividad e innovación de las micro, pequeñas y medianas empresas ubicadas en sectores estratégicas, que impulse el fortalecimiento ordenado, planificado y sistemático del emprendimiento y del desarrollo empresarial en todo el territorio nacional, así como la consolidación de una economía innovadora, dinámica y competitiva</p>
          </Col>
          <Col md={4}>
            <h4 className="fw-bold">Contacto</h4>
            <ul class="list-unstyled m-0">
              <li className="mb-2 fw-bold">Direccion</li>
              <li className="mb-2">Oficinas centrales CDMX</li>
              <li className="mb-2 fw-bold">Correo</li>
              <li className="mb-2">contacto@emprendimientoconimpulso.org.mx</li>
              <li className="mb-2 fw-bold">Telefono</li>
              <li>Tel.5540076098</li>
            </ul>
          </Col>
          <Col md={4}>
            <h4 className="fw-bold">Nosotros</h4>
            <p>Nuestro Consejo Directivo está conformado por emprendedoras y emprendedores que, de forma honoraria, dedican su tiempo a las actividades más estratégicas de la asociación. Se renueva cada tres años por votación de la Asamblea General. La Presidencia del Consejo Directivo es elegida de entre los integrantes del mismo Consejo Directivo.</p>
            <Nav.Link as={Link} to="/Aviso" className='navLinkStyle text-center m-3'>Aviso de Privacidad</Nav.Link>
          </Col>
        </Row>
        <div className='text-center text-white p-3 ' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        Copyright © 2023 Desarrollo al Comercio. Todos los derechos reservados.
       
      </div>
      </Container>
    </footer>
    </section>
    </div>
  );
}

export default Home;