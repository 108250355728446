// SocialMediaLinks.js

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaWhatsapp, FaInstagram } from 'react-icons/fa';
import './css/icons.css';  // Importa el archivo de estilos

const SocialMediaLinks = () => {
  return (
    
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs="auto" className="px-2">
          <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-white">
            <FaFacebook size={24} className="social-icon-link" />
          </a>
        </Col>
        <Col xs="auto" className="px-2">
          <a href="https://api.whatsapp.com/send?phone=123456789" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-white">
            <FaWhatsapp size={24} className="social-icon-link" />
          </a>
        </Col>
        <Col xs="auto" className="px-2">
          <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-white">
            <FaInstagram size={24} className="social-icon-link" />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default SocialMediaLinks;
